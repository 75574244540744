import React from "react";
import AuthenticationService from "./services/AuthenticationService";
import HomeService from "./services/HomeService";
import ApplicationService from "./services/ApplicationService";
import moment from "moment";
import { LocaleProvider } from "./i18n/LocaleProvider";
import ConfigurationPropertiesContext from "./contexts/ConfigurationPropertiesContext";
import { Col, Container, Row } from "react-bootstrap";
import Navbar from "./components/common/Navbar";
import Loading from "./components/common/display/Loading";
import { Route, Switch } from "react-router-dom";
//import Home from "./components/common/Home";
import ProtectedRoute from "./components/common/ProtectedRoute";
import {
  PERMISSION_ASSESS_EMPLOYEES,
  PERMISSION_MANAGE_GOAL_TEMPLATES,
  PERMISSION_MANAGE_ORGANIZATIONAL_GOALS,
  PERMISSION_MANAGE_PROCESS,
  PERMISSION_MANAGE_ROLES,
  PERMISSION_MANAGE_TEAMS,
  PERMISSION_MANAGE_USERS,
  PERMISSION_OVERVIEW,
  PERMISSION_SELF_ASSESS,
  PERMISSION_SET_GOALS,
  PERMISSION_TEAM_HISTORY,
  PERMISSION_VIEW_AUDIT,
  PERMISSION_VIEW_HISTORY,
  MANAGER_FEEDBACK_VIEW,
  MANAGER_FEEDBACK_VIEW_ALL,
  VIEWER,
  SET_ONE_ON_ONE,
  MANAGE_ONE_ON_ONE_FEEDBACK_QUESTIONS,
  PERMISSION_MY_TRAINING,
  PERMISSION_TRAINING_STATUS_MANAGER,
  PERMISSION_TRAINING_REPORTING,
  PERMISSION_MANAGER_TRAININGS,
} from "./constants/permissions";
import UsersTrainings from "./components/trainings/StatusManager/UsersTrainings";
import Reporting from "./components/trainings/Reporting/Reporting";
import ReportingStatistcs from "./components/trainings/Reporting/ReportingStatistics";
import MyTraining from "./components/trainings/MyTraining/MyTraining";
import StatusManager from "./components/trainings/StatusManager/StatusManager";
import MyGoalsContainer from "./components/goal/MyGoalsContainer";
import MyLastAppraisal from "./components/appraisal/MyLastAppraisal";
import UserGoalsDefinitionList from "./components/manager/UserGoalsDefinitionList";
import ManagerContainer from "./components/manager/ManagerContainer";
import WithManagerAndPermission from "./components/common/WithManagerAndPermission";
import MyAppraisalsContainer from "./components/appraisal/MyAppraisalsContainer";
import HRAppraisalsContainer from "./components/hr/HRAppraisalsContainer";
import ManagerAppraisalsContainer from "./components/manager/ManagerAppraisalsContainer";
import EditGoalsContainer from "./components/manager/EditGoalsContainer";
import AllUsers from "./components/admin/AllUsers";
import RolesContainer from "./components/admin/roles/RolesContainer";
import TeamsContainer from "./components/admin/teams/TeamsContainer";
import OneOnOneFeedbackQuestionsContainer from "./components/admin/oneOnOnes/OneOnOneFeedbackQuestionsContainer";
import ProcessesContainer from "./components/hr/ProcessesContainer";
import Process from "./components/hr/Process";
import ProcessDashboard from "./components/hr/ProcessDashboard";
import ProcessParticipants from "./components/hr/ProcessParticipants";
import Audit from "./components/admin/Audit";
import PostFinalAssessment from "./components/appraisal/PostFinalAssessment";
import Overview from "./components/hr/Overview";
import OrganizationalGoalsTemplatesContainer from "./components/goal/OrganizationalGoalsTemplatesContainer";
import GoalsTemplatesContainer from "./components/goal/GoalsTemplatesContainer";
import WithHistoryManagerAndPermission from "./components/common/WithHistoryManagerAndPermission";
import WithAssessorProved from "./components/common/WithAssessorProved";
import WithAssessorProvedAndPermission from "./components/common/WithAssessorProvedAndPermission";
import AppraisalManage from "./components/appraisal/AppraisalManage";
import {
  EMPLOYEE_ASESSOR_TYPE,
  FINAL_ASSESSOR_TYPE,
  MANAGER_ASSESSOR_TYPE,
  NO_ASSESSOR_TYPE,
} from "./constants/assessorTypes";
import WithManagerProved from "./components/manager/WithManagerProved";
import WithHRProved from "./components/hr/WithHRProved";
import Review from "./components/hr/Review";
import "./app.css";
import "./toastr.css";
import AllManagersFeedbackGroup from "./components/manager/feedback/AllManagersFeedbackList";
import ManagerFeedback from "./components/manager/feedback/ManagerFeedback";
import Login from "./security/Login";
import Lobby from "./security/Lobby";
import ForgotPassword from "./security/ForgotPassword";
import CustomFooter from "./components/common/display/CustomFooter";
import MyProfileContainer from "./components/profile/MyProfileContainer";
import MyTeamContainer from "./components/admin/teams/MyTeamContainer";
import Home from "./components/common/Home";
import ProfileRoute from "./components/common/naviguation/ProfileRoutes";
import OneOnOneByRoleContainer from "./components/oneOnOne/OneOnOneByRoleContainer";
import TeamTrainings from "./components/trainings/TeamTraining/TeamTrainings";
import PerformanceReviewTeamTrainings from "./components/trainings/TeamTraining/PerformanceReviewTeamTrainings";

export default class App extends React.Component {
  state = {
    user: null,
    runAsMeAvailable: false,
    versionData: null,
    configProps: undefined,
    loading: true,
  };

  componentDidMount() {
    Promise.all([
      AuthenticationService.getCurrentUser(),
      HomeService.isRunAsMeAvailable(),
      ApplicationService.getApplicationVersion(),
      ApplicationService.getApplicationConfigurationProperties(),
    ])
      .then(([user, runAsMeAvailable, version, configProps]) =>
        this.setState({
          user,
          runAsMeAvailable,
          versionData: version.build ? version : null,
          configProps: {
            formatDate: (date) =>
              moment(date).format(configProps.dateFormatterPattern),
            formatDateTime: (dateTime) =>
              moment(dateTime).format(configProps.dateTimeFormatterPattern),
            internalUserManagement: configProps.internalUserManagement,
            team: configProps.team,
            teamEmail: configProps.teamEmail,
            company: configProps.company,
          },
          loading: false,
        })
      )
      .catch(() =>
        this.setState({
          loading: false,
        })
      );
  }

  render() {
    return (
      <LocaleProvider>
        <ConfigurationPropertiesContext.Provider value={this.state.configProps}>
          <Container className="container-relative" fluid>
            {this.state.loading ? (
              <Loading />
            ) : (
              <>
                <Switch>
                  {this?.props?.configProps?.internalUserManagement && (
                    <Route exact path="/login">
                      <Login />
                    </Route>
                  )}

                  {this?.props?.configProps?.internalUserManagement && (
                    <Route exact path="/password">
                      <ForgotPassword />
                    </Route>
                  )}
                  {this.state.user !== null ? (
                    <>
                      <Navbar
                        user={this.state.user}
                        runAsMeAvailable={this.state.runAsMeAvailable}
                      />
                      <Row>
                        <Col>
                          <Route exact path={"/"}>
                            <MyProfileContainer
                              user={this.state.user}
                              loading={this.state.loading}
                              configProps={this.state.configProps}
                            />
                            <Home
                              user={this.state.user}
                              configProps={this.state.configProps}
                            />
                          </Route>
                          <ProfileRoute
                            exact
                            path={"/my-goals"}
                            component={MyGoalsContainer}
                            user={this.state.user}
                            permission={PERMISSION_SELF_ASSESS}
                            readOnly={true}
                            loading={this.state.loading}
                            configProps={this.state.configProps}
                          />
                          <ProfileRoute
                            exact
                            path={"/appraisal"}
                            component={MyLastAppraisal}
                            user={this.state.user}
                            permission={PERMISSION_SELF_ASSESS}
                            loading={this.state.loading}
                            configProps={this.state.configProps}
                          />
                          <ProfileRoute
                            exact
                            path={"/appraisals"}
                            component={MyAppraisalsContainer}
                            user={this.state.user}
                            permission={PERMISSION_SELF_ASSESS}
                            loading={this.state.loading}
                            configProps={this.state.configProps}
                          />
                          <ProfileRoute
                            exact
                            path={"/manager"}
                            component={ManagerContainer}
                            user={this.state.user}
                            permission={PERMISSION_ASSESS_EMPLOYEES}
                            loading={this.state.loading}
                            configProps={this.state.configProps}
                          />
                          <ProfileRoute
                            exact
                            path={"/oneOnOnes"}
                            component={OneOnOneByRoleContainer}
                            user={this.state.user}
                            permission={SET_ONE_ON_ONE}
                            loading={this.state.loading}
                            configProps={this.state.configProps}
                          />
                          <ProfileRoute
                            exact
                            path={"/my-team"}
                            component={MyTeamContainer}
                            user={this.state.user}
                            permission={PERMISSION_SELF_ASSESS}
                            loading={this.state.loading}
                            configProps={this.state.configProps}
                          />
                          <ProfileRoute
                            exact
                            path={"/viewer/users/:id/goals"}
                            component={UserGoalsDefinitionList}
                            user={this.state.user}
                            permission={VIEWER}
                          />
                          <Route
                            exact
                            path={"/manager/users/:id/goals"}
                            render={() => (
                              <WithManagerAndPermission
                                user={this.state.user}
                                permission={PERMISSION_ASSESS_EMPLOYEES}
                                teamAccessView={true}
                              >
                                <MyProfileContainer
                                  user={this.state.user}
                                  loading={this.state.loading}
                                  configProps={this.state.configProps}
                                />
                                <UserGoalsDefinitionList
                                  user={this.state.user}
                                  readOnly={true}
                                />
                              </WithManagerAndPermission>
                            )}
                          />
                          <ProtectedRoute
                            exact
                            path={"/feedback"}
                            component={AllManagersFeedbackGroup}
                            user={this.state.user}
                            permission={MANAGER_FEEDBACK_VIEW_ALL}
                          />
                          <ProtectedRoute
                            exact
                            path={"/users/:id/history"}
                            component={HRAppraisalsContainer}
                            user={this.state.user}
                            permission={PERMISSION_VIEW_HISTORY}
                          />
                          <Route
                            exact
                            path="/manager/users/:id/history"
                            render={() => (
                              <WithHistoryManagerAndPermission
                                user={this.state.user}
                                permission={PERMISSION_TEAM_HISTORY}
                                teamAccessView={true}
                              >
                                <MyProfileContainer
                                  user={this.state.user}
                                  loading={this.state.loading}
                                  configProps={this.state.configProps}
                                />
                                <ManagerAppraisalsContainer
                                  user={this.state.user}
                                />
                              </WithHistoryManagerAndPermission>
                            )}
                          />
                          <ProtectedRoute
                            exact
                            path={"/users/:id/goals"}
                            component={UserGoalsDefinitionList}
                            user={this.state.user}
                            permission={PERMISSION_OVERVIEW}
                          />
                          <ProfileRoute
                            exact
                            path={"/prg/:id/goals"}
                            component={EditGoalsContainer}
                            user={this.state.user}
                            permission={PERMISSION_SET_GOALS}
                            loading={this.state.loading}
                            configProps={this.state.configProps}
                          />
                          <ProtectedRoute
                            exact
                            path={"/admin/users"}
                            component={AllUsers}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_USERS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/admin/roles"}
                            component={RolesContainer}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_ROLES}
                            disableScrollToTop={true}
                          />
                          <ProtectedRoute
                            exact
                            path={"/admin/teams"}
                            component={TeamsContainer}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_TEAMS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/admin/one-on-ones/feedback-questions"}
                            component={OneOnOneFeedbackQuestionsContainer}
                            user={this.state.user}
                            permission={MANAGE_ONE_ON_ONE_FEEDBACK_QUESTIONS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/reviews"}
                            component={Review}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_PROCESS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/reviews/:id/processes"}
                            component={ProcessesContainer}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_PROCESS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/processes"}
                            component={Process}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_PROCESS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/processes/:id/dashboard"}
                            component={ProcessDashboard}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_PROCESS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/processes/:id/participants"}
                            component={ProcessParticipants}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_PROCESS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/audit"}
                            component={Audit}
                            user={this.state.user}
                            permission={PERMISSION_VIEW_AUDIT}
                          />
                          <Route
                            exact
                            path="/users/:id/appraisal/goals"
                            render={(props) => (
                              <WithManagerAndPermission
                                user={this.state.user}
                                permission={PERMISSION_ASSESS_EMPLOYEES}
                                {...props}
                              >
                                <MyProfileContainer
                                  user={this.state.user}
                                  loading={this.state.loading}
                                  configProps={this.state.configProps}
                                />
                                <PostFinalAssessment user={this.state.user} />
                              </WithManagerAndPermission>
                            )}
                          />
                          <ProtectedRoute
                            exact
                            path="/overview"
                            component={Overview}
                            user={this.state.user}
                            permission={PERMISSION_OVERVIEW}
                          />
                          <ProtectedRoute
                            exact
                            path="/organizational-goals/templates"
                            component={OrganizationalGoalsTemplatesContainer}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_ORGANIZATIONAL_GOALS}
                          />
                          <ProtectedRoute
                            exact
                            path="/goals/templates"
                            component={GoalsTemplatesContainer}
                            user={this.state.user}
                            permission={PERMISSION_MANAGE_GOAL_TEMPLATES}
                          />
                          <ProtectedRoute
                            exact
                            path="/training/reporting"
                            component={Reporting}
                            user={this.state.user}
                            permission={PERMISSION_TRAINING_REPORTING}
                          />
                          <ProtectedRoute
                            exact
                            path="/training/reporting-statistics/:performanceReviewId"
                            component={ReportingStatistcs}
                            user={this.state.user}
                            permission={PERMISSION_TRAINING_REPORTING}
                          />
                          <ProtectedRoute
                            exact
                            path="/training/status-manager"
                            component={StatusManager}
                            user={this.state.user}
                            permission={PERMISSION_TRAINING_STATUS_MANAGER}
                          />
                          <ProtectedRoute
                            exact
                            path="/training/users/:performanceReviewId"
                            component={UsersTrainings}
                            user={this.state.user}
                            permission={PERMISSION_TRAINING_STATUS_MANAGER}
                          />
                          <ProfileRoute
                            exact
                            path={"/training/my-training"}
                            component={MyTraining}
                            user={this.state.user}
                            permission={PERMISSION_MY_TRAINING}
                            readOnly={true}
                            loading={this.state.loading}
                            configProps={this.state.configProps}
                          />
                          <ProtectedRoute
                            exact
                            path={"/performance-review/team-trainings"}
                            component={PerformanceReviewTeamTrainings}
                            user={this.state.user}
                            permission={PERMISSION_MANAGER_TRAININGS}
                          />
                          <ProtectedRoute
                            exact
                            path={"/team-trainings/:performanceReviewId"}
                            component={TeamTrainings}
                            user={this.state.user}
                            permission={PERMISSION_MANAGER_TRAININGS}
                          />
                          <Route
                            exact
                            path="/appraisals/:id"
                            render={(props) => (
                              <WithAssessorProved
                                user={this.state.user}
                                {...props}
                              >
                                <MyProfileContainer
                                  user={this.state.user}
                                  loading={this.state.loading}
                                  configProps={this.state.configProps}
                                />
                                <AppraisalManage
                                  user={this.state.user}
                                  assessorType={EMPLOYEE_ASESSOR_TYPE}
                                  {...props}
                                />
                              </WithAssessorProved>
                            )}
                          />

                          <Route
                            exact
                            path="/appraisals/:id/feedback"
                            render={(props) => {
                              const user =
                                props.location.state?.user || this.state.user;

                              return (
                                <WithAssessorProvedAndPermission
                                  user={user}
                                  permission={MANAGER_FEEDBACK_VIEW}
                                  {...props}
                                >
                                  <MyProfileContainer
                                    user={user}
                                    loading={this.state.loading}
                                    configProps={this.state.configProps}
                                  />
                                  <ManagerFeedback user={user} {...props} />
                                </WithAssessorProvedAndPermission>
                              );
                            }}
                          />
                          <Route
                            exact
                            path="/appraisals/:id/assess"
                            render={(props) => (
                              <WithManagerProved
                                {...props}
                                user={this.state.user}
                                command="assess"
                              >
                                <MyProfileContainer
                                  user={this.state.user}
                                  loading={this.state.loading}
                                  configProps={this.state.configProps}
                                />
                                <AppraisalManage
                                  {...props}
                                  assessorType={MANAGER_ASSESSOR_TYPE}
                                  user={this.state.user}
                                />
                              </WithManagerProved>
                            )}
                          />
                          <Route
                            exact
                            path="/appraisals/:id/view"
                            render={(props) => (
                              <WithManagerProved
                                {...props}
                                user={this.state.user}
                                command="view"
                                teamAccessView={true}
                              >
                                <MyProfileContainer
                                  user={this.state.user}
                                  loading={this.state.loading}
                                  configProps={this.state.configProps}
                                />
                                <AppraisalManage
                                  {...props}
                                  assessorType={MANAGER_ASSESSOR_TYPE}
                                  user={this.state.user}
                                />
                              </WithManagerProved>
                            )}
                          />
                          <Route
                            exact
                            path="/appraisals/:id/overview"
                            render={(props) => (
                              <WithHRProved {...props} user={this.state.user}>
                                <AppraisalManage
                                  {...props}
                                  assessorType={NO_ASSESSOR_TYPE}
                                  user={this.state.user}
                                />
                              </WithHRProved>
                            )}
                          />
                          <Route
                            exact
                            path="/appraisals/:id/review"
                            render={(props) => (
                              <WithManagerProved
                                {...props}
                                user={this.state.user}
                                command="review"
                              >
                                <MyProfileContainer
                                  user={this.state.user}
                                  loading={this.state.loading}
                                  configProps={this.state.configProps}
                                />
                                <AppraisalManage
                                  {...props}
                                  assessorType={FINAL_ASSESSOR_TYPE}
                                  user={this.state.user}
                                />
                              </WithManagerProved>
                            )}
                          />
                        </Col>
                      </Row>
                      <CustomFooter versionData={this.state.versionData} />
                    </>
                  ) : (
                    <>
                      <Lobby
                        appProps={
                          this.props.configProps !== null
                            ? this.props.configProps
                            : this.state.configProps
                        }
                      />
                    </>
                  )}
                </Switch>
              </>
            )}
          </Container>
        </ConfigurationPropertiesContext.Provider>
      </LocaleProvider>
    );
  }
}
