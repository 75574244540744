import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button, Table } from "react-bootstrap";
import { FormattedMessage } from "react-intl";
import ConfigurationPropertiesContext from "../../contexts/ConfigurationPropertiesContext";
import AuthenticationService from "../../services/AuthenticationService";
import { getAppraisalTitle } from "../../util/getAppraisalTitle";
import {
  EMPLOYEE_ASESSOR_TYPE,
  MANAGER_ASSESSOR_TYPE,
} from "../../constants/assessorTypes";
import {
  APPRAISAL_STATUS_ACTIVE,
  APPRAISAL_STATUS_CLOSED,
  APPRAISAL_STATUS_REVIEW,
} from "../../constants/appraisalStatus";
import { MANAGER_FEEDBACK_VIEW } from "../../constants/permissions";
import ScoreCircle from "../common/ScoreCircle";
import ManagerFeedbackAvgScore from "./ManagerFeedbackAvgScore";
import "./appraisals.css";

const Appraisals = ({ appraisals, employee, manager }) => {
  const location = useLocation();
  const history = useHistory();

  const isEmployee = location.pathname.includes("/appraisals");
  const isManager = location.pathname.includes("/manager");
  const isDirectManager = isManager && employee?.managerId === manager?.id;
  const canViewManagerFeedback = AuthenticationService.hasPermission(
    employee,
    MANAGER_FEEDBACK_VIEW
  );

  return (
    <ConfigurationPropertiesContext.Consumer>
      {({ formatDate }) => (
        <Table className="appraisals" borderless>
          <thead>
            <tr>
              <th>
                <FormattedMessage id="Manager.assessmentPeriod" />
              </th>
              {isEmployee || isDirectManager ? (
                <th>
                  <FormattedMessage id="Appraisal.myAssessment" />
                </th>
              ) : (
                <>
                  {!isManager && (
                    <th>
                      <FormattedMessage id="Appraisal.employeeAssesment" />
                    </th>
                  )}
                  <th>
                    <FormattedMessage id="Appraisal.managerAssesment" />
                  </th>
                </>
              )}
              <th>
                <FormattedMessage id="Appraisal.finalAssesment" />
              </th>
              {canViewManagerFeedback && (
                <th>
                  <FormattedMessage id="Appraisal.managerFeedback" />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {appraisals.map((appraisal, index) => {
              return (
                <tr
                  key={`appraisal-row-${index}`}
                  className={
                    (isEmployee && appraisal.assessors[0].draft) ||
                    (isManager &&
                      appraisal.status === APPRAISAL_STATUS_ACTIVE &&
                      appraisal.assessors[0].draft) ||
                    (isManager &&
                      appraisal.status === APPRAISAL_STATUS_REVIEW &&
                      appraisal.assessors[1].draft)
                      ? "appraisal-active"
                      : ""
                  }
                >
                  <td>
                    <div className="appraisal-title">{`${getAppraisalTitle(
                      appraisal.type
                    )} assessment`}</div>
                    <small>{`${formatDate(appraisal.startDate)} - ${formatDate(
                      appraisal.endDate
                    )}`}</small>
                  </td>
                  {appraisal.assessors.map((assessor, index) => (
                    <td
                      key={`assessor-${index}`}
                      onClick={appraisal.viewAction}
                    >
                      {appraisal.status !== APPRAISAL_STATUS_CLOSED &&
                      assessor.draft ? (
                        (assessor.type === EMPLOYEE_ASESSOR_TYPE ||
                          assessor.type === MANAGER_ASSESSOR_TYPE ||
                          appraisal.interviewAction) &&
                        (isEmployee || isDirectManager) ? (
                          <div className="action">
                            <Button
                              variant="primary"
                              size="sm"
                              onClick={
                                appraisal.interviewAction ||
                                appraisal.assessAction
                              }
                            >
                              <FormattedMessage id="Appraisal.fillIn" />
                            </Button>
                            <small>
                              {<FormattedMessage id="Manager.dueDate" />}
                              {`: ${formatDate(appraisal.dueDate)}`}
                            </small>
                          </div>
                        ) : (
                          <div className="appraisal-pending">
                            <FormattedMessage id="Appraisal.pending" />
                          </div>
                        )
                      ) : (
                        <div className="appraisal-score">
                          <ScoreCircle score={assessor.performanceScore} />
                        </div>
                      )}
                    </td>
                  ))}
                  {canViewManagerFeedback &&
                    (appraisal?.processStatus === "CLOSED" ? (
                      <td
                        key={`appraisal-row-${index}`}
                        onClick={() => {
                          history.push({
                            pathname: `/appraisals/${appraisal.id}/feedback`,
                            state: {
                              user: employee,
                              processId: `${appraisal?.processId}`,
                              performanceReviewId: `${appraisal?.performanceReviewId}`,
                            },
                          });
                        }}
                      >
                        <div className="appraisal-score">
                          <ManagerFeedbackAvgScore
                            userId={employee?.id}
                            processId={appraisal?.processId}
                            performanceReviewId={appraisal?.performanceReviewId}
                          />
                        </div>
                      </td>
                    ) : (
                      <td>
                        <div className="appraisal-pending">
                          <FormattedMessage id="Appraisal.pending" />
                        </div>
                      </td>
                    ))}
                </tr>
              );
            })}
          </tbody>
        </Table>
      )}
    </ConfigurationPropertiesContext.Consumer>
  );
};

export default Appraisals;
